import http from "../js/http-commons";
const API_URL = "/bbs";

export default {
  getAll(params) {
    return http.get(API_URL + "/bbsList", { params });
  },

  get(id) {
    return http.get(API_URL + `/bbsDetail/${id}`);
  },
  create(params, onUploadProgress) {
    let formData = new FormData();

    // let reqparam = {
    //   title: params.title
    // , description: params.description
    // };

    //  formData.append("data", reqparam);
    formData.append("bbsId", params.bbsId);
    formData.append("title", params.title);
    formData.append("description", params.description);
    formData.append("file", params.file);

    let config = {
      header: {
        "Content-Type": "multipart/form-data;charset=utf-8"
      }
    };

    return http.post(
      API_URL + `/bbsSave`,
      //  params ,
      formData,
      // config,
      onUploadProgress
    );
    // .then(res => { console.log(res) });

    // return http.post(API_URL + "/bbsSave", formData, {
    // // return http.post(API_URL + "/bbsSave", data, formData, {
    // // return http.post(API_URL + "/bbsSave", data, formData, {
    //   headers: {
    //     "Content-Type": "multipart/form-data"
    //   },
    //   onUploadProgress
    // }).then(res => { console.log(res)});
  },

  update(params) {
    let formData = new FormData();

    // let reqparam = {
    //   title: params.title
    // , description: params.description
    // };

    // formData.append("data", params);

    formData.append("id", params.bbsId);
    formData.append("title", params.title);
    formData.append("description", params.description);
    formData.append("file", params.file);
    formData.append("isImage", params.isImage);

    let config = {
      header: {
        "Content-Type": "multipart/form-data;charset=utf-8"
      }
    };

    return http.post(
      // API_URL + `/bbsModify`,
      API_URL + `/bbsModify/${params.bbsId}`,
      formData
      // params,
      // config,
    );
  },

  delete(params) {
    // let formData = new FormData();

    // formData.append("id", params.reqId);

    let config = {
      header: {
        "Content-Type": "multipart/form-data;charset=utf-8"
      }
    };

    return http.delete(
      API_URL + `/bbsRemove/${params.reqId}`
      // ,formData
    );
  },

  // deleteAll() {
  //   return http.delete(`/boardAllDelete`);
  // },

  findByTitle(title) {
    return http.get(API_URL + `/bbsList?title=${title}`);
  }

  // upload(file, onUploadProgress) {
  //   let formData = new FormData();

  //   formData.append("file", file);

  //   return http.post("/boardUpload", formData, {
  //     headers: {
  //       "Content-Type": "multipart/form-data"
  //     },
  //     onUploadProgress
  //   });
  // },

  // getFiles() {
  //   return http.get("/files");
  // }
};
// export default new BbsDataService();
