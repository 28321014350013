<template>
  <div class="mt-5">
    <!-- flat -->
    <v-card class="mx-auto" max-width="700" tile>
      <v-container>
        <v-col> </v-col>
        <v-row align="center" class="mx-auto" tile dense md="1">
          <v-col cols="12" sm="12">
            <div class="text-h6 px-4 pt-4 ">
              {{ this.title }}
            </div>
            <v-divider></v-divider>
          </v-col>
          <v-col
            v-for="item in resFiles"
            :key="item.id"
            cols="12"
            sm="12"
            class="mt-5"
            align="left"
          >
            <img
              :src="item.fileUrl"
              :alt="item.name"
              height="100%"
              width="90%"
              class="white--text align-end"
            />
          </v-col>
          <v-col>
            <div class="text-h6 px-4 pt-4 ">
              <p v-html="this.description"></p>
            </div>
            <br />
            <v-divider></v-divider>
            <br />
            <div class="list px-3 mx-auto" align="center">
              <button @click="goList" class="btn btn-success">목록</button>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button
                v-if="currentUser"
                @click="goModify(bbsId)"
                class="btn btn-success"
              >
                수정
              </button>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button
                v-if="currentUser"
                @click="goRemove(bbsId)"
                class="btn btn-success"
              >
                삭제
              </button>
            </div>
            <br />
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <!-- </div> -->
    <div class="alert alert-info" v-if="succMessage">
      <!-- <strong>Warning!</strong> -->
      조회 되었습니다.
    </div>
  </div>
</template>

<script>
import bbsDataService from "../services/BbsDataService";

export default {
  name: "bbsDetail",
  components: {},
  data() {
    return {
      bbsId: this.$route.params.id,
      resFiles: [],
      fileUrl: "",
      title: "",
      description: "",
      fileName: "",
      content: "",
      serverError: "",
      requestSubmitted: "",
      succMessage: "",
      props: {
        propcurrPage: {
          type: Number,
          required: true,
          default: 0
        }
      },
      currPage: this.$route.params.propcurrPage
    };
  },
  computed: {
    currentUser() {
      // console.log("computed-currentUser");
      return this.$store.state.auth.user;
    }
  },
  methods: {
    getDisplayFiles(item) {
      return {
        id: item.id,
        fileUrl: item.fileUrl,
        name:
          item.name.length >= 10 ? item.name.substr(0, 20) + "..." : name.name
      };
    },
    goList() {
      this.$router.push({
        path: "/bbslist/" + this.currPage
      });
    },
    goModify(reqId) {
      this.$router.push({
        name: "bbsmodify",
        params: {
          id: reqId,
          currPage: this.currPage
        }
      });
    },
    goRemove(reqId) {
      if (!confirm("삭제 하시겠습니까?")) {
        return;
      }
      let params = {};
      if (reqId) {
        params["reqId"] = reqId;
      } else {
        return;
      }
      bbsDataService
        .delete(params)
        .then(response => {
          alert("삭제 되었습니다.");
          return this.$router.push({
            name: "bbslist",
            params: {
              currPage: this.currPage
            }
          });
        })
        .catch(e => {
          this.progress = 0;
          this.message = "삭제에 실패 하였습니다.";
          console.log(e);
        });
    }
  },
  created() {},
  mounted() {
    bbsDataService
      .get(this.$route.params.id)
      .then(
        response => {
          // console.log('#######httpstatuscode>',getReasonPhrase(response.status));
          //  this.serverError = true;
          this.requestSubmitted = true;
          // console.log('response>',response);
          const { fileDB, description, message } = response.data.bbsDetailData;
          this.resFiles = fileDB.map(this.getDisplayFiles);
          this.id = response.data.bbsDetailData.id;
          this.title = response.data.bbsDetailData.title;
          this.description = response.data.bbsDetailData.description;
          if (this.description) {
            this.description = this.description.replace(
              /(?:\r\n|\r|\n)/g,
              "<br />"
            );
          }
          this.content = response.data.bbsDetailData;

          // if(response.status===200){
          //    this.succMessage = true;
          // }
          this.content = response.data.bbsDetailData.status;
        },
        error => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      )
      .catch(error => {
        this.serverError = true;
        console.log(error);
        this.errored = true;

        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }

        console.log(error.config);
      })
      .finally(() => {});
    // .finally(() => this.loading = false)
  }
};
</script>
